/* eslint-disable prefer-promise-reject-errors */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ErrorResponse } from '@remix-run/router';
import { fDate } from 'src/modules/shared/utils/format-time';
import axiosInstance from '../../shared/utils/axios';

export const filterProjects = createAsyncThunk(
  'projects/filterProjects',
  async ({ name, status }: { name: string; status: string }, thunkAPI) => {
    let data;
    try {
      const response = await axiosInstance.get(`/projects?name=${name}&status=${status}`);

      data = response.data;
      if (response.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
export const filterSwitchProjects = createAsyncThunk(
  'projects/filterSwitchProjects',
  async ({ name, status }: { name: string; status: string }, thunkAPI) => {
    let data;
    try {
      const response = await axiosInstance.get(
        `/projects?switch_name=${name}${status && `&status=${status}`}`
      );

      data = response.data;
      if (response.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
export const fetchProjects = createAsyncThunk(
  'projects/fetchProjects',
  async ({
    name,
    status,
    startDate,
    endDate,
    condition,
    page = 1,
    page_size = 7,
  }: {
    name?: string;
    status?: string;
    startDate?: string;
    endDate?: string;
    condition?: string;
    page?: number;
    page_size?: number;
  }) => {
    const paramsObject = {
      status,
      start_date: startDate,
      end_date: endDate,
      condition,
      page,
      page_size,
      name,
    };
    const filteredParams = Object.fromEntries(
      Object.entries(paramsObject).filter(
        ([_, value]) => value !== undefined && value !== null && value.toString().length > 0
      )
    );

    const res = await axiosInstance.get(`/projects`, {
      params: filteredParams,
    });
    const projects = res.data.results.map((project: any) => {
      const formattedProject = { ...project };
      formattedProject['startDate'] = fDate(formattedProject['start_date']);
      formattedProject['endDate'] = fDate(formattedProject['due_date']);
      delete formattedProject['start_date'];
      delete formattedProject['due_date'];

      return formattedProject;
    });
    const nbProjects = res.data.count;
    const nbAll = res.data.all_count;
    const nbDone = res.data.done_status_count;
    const nbSoon = res.data.due_soon_status_count;
    const nbOverdue = res.data.overdue_status_count;
    const next = res.data.next;
    const prev = res.data.prev;
    return { projects, nbProjects, nbAll, nbDone, nbSoon, nbOverdue, next, prev };
  }
);

export const fetchAllProjects = createAsyncThunk('projects/fetchAllProjects', async () => {
  const res = await axiosInstance.get(`/projects`, {
    params: { page_size: 10000 },
  });

  return res;
});

export const fetchUserHistory = createAsyncThunk(
  'projects/fetchUserHistory',
  async ({
    userId,
    status = '',
    condition = '',
    page = 1,
    page_size = 7,
  }: {
    userId: string;
    status?: string;
    condition?: string;
    page?: number;
    page_size?: number;
  }) => {
    const res = await axiosInstance.get(`/user/history/${userId}`, {
      params: { status, condition, page, page_size },
    });

    const data = res.data.data;

    const member = data.user;
    const historyProj = data.history.filter((proj: any) => proj.is_active === false);
    const activeProj = data.history.filter((proj: any) => proj.is_active === true);

    return { member, historyProj, activeProj };

    // .results.map((project: any) => {
    //   const formattedProject = { ...project };
    //   formattedProject['startDate'] = fDate(formattedProject['start_date']);
    //   formattedProject['endDate'] = fDate(formattedProject['due_date']);
    //   delete formattedProject['start_date'];
    //   delete formattedProject['due_date'];

    //   return formattedProject;
    // });
    // const nbProjects = res.data.count;
    // const nbAll = res.data.all_count;
    // const nbDone = res.data.done_status_count;
    // const nbSoon = res.data.due_soon_status_count;
    // const nbOverdue = res.data.overdue_status_count;
    // const next = res.data.next;
    // const prev = res.data.prev;
    // return { projects, nbProjects, nbAll, nbDone, nbSoon, nbOverdue, next, prev };
  }
);

export const deleteProjects = createAsyncThunk(
  'projects/deleteProjects',
  async (ids: any, thunkAPI) => {
    let data: any;
    try {
      const deletePromises = ids?.map(async (id: any) => {
        try {
          await axiosInstance.delete(`/projects/${id}`);
        } catch (error) {
          console.error(`Error deleting project with ID ${id}:`, error);
        }
      });

      try {
        await Promise.all(deletePromises);
      } catch (error) {
        console.error('Error deleting projects:', error);
      }

      return { data, status: 'success' };
    } catch (err) {
      return Promise.reject({
        message: err.message ? err.message : data?.message,
      });
    }
  }
);
export const fetchOneProject = createAsyncThunk(
  'projects/fetchOneProject',
  async (id: string, thunkAPI) => {
    let data;
    try {
      const response = await axiosInstance.get(`/projects/${id}`);
      data = await response.data;
      if (response.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
export const createProject = createAsyncThunk(
  'projects/createProject',
  async (payload: FormData, thunkAPI) => {
    let data;
    try {
      const response = await axiosInstance.post(`/projects/`, payload, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      data = await response.data;
      if (response.status === 201) {
        return data;
      }

      throw new Error(response.statusText);
    } catch (err) {
      // return Promise.reject(err.message ? err.message : data?.message);
      return thunkAPI.rejectWithValue(err as ErrorResponse);
    }
  }
);

export const updateProject = createAsyncThunk(
  'projects/updateProject',
  async (payload: any, thunkAPI) => {
    let data;
    const id = payload?.id || payload.get('id');
    try {
      const response = await axiosInstance.patch(`/projects/${id}`, payload, {
        // headers: { 'Content-Type': 'multipart/form-data' },
      });
      data = response.data;
      if (response.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return thunkAPI.rejectWithValue(err as ErrorResponse);
    }
  }
);

export const switchMembers = createAsyncThunk(
  'projects/switchMembers',
  async (payload: any, thunkAPI) => {
    let data;

    try {
      const response = await axiosInstance.put(`/projects/switch-member/`, payload);
      data = await response.data;
      if (response.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

export const removeMemberFromProject = createAsyncThunk(
  'projects/removeMemberFromProject',
  async (payload: any, thunkAPI) => {
    let data;

    try {
      const response = await axiosInstance.put(`/projects/${payload.projectId}/remove-member/`, {
        id: payload.memberId,
      });
      data = await response.data;
      if (response.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

export const addMemberToProject = createAsyncThunk(
  'projects/addMemberToProject',
  async (payload: any, thunkAPI) => {
    let data;

    try {
      const response = await axiosInstance.put(`/projects/${payload.projectId}/add-member/`, {
        id: payload.memberId,
      });
      data = await response.data;
      if (response.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

export const addExtraHoursToMember = createAsyncThunk(
  'projects/addExtraHours',
  async (
    payload: {
      member: string;
      project: string;
      hours: string;
      date: string;
    },
    thunkAPI
  ) => {
    try {
      const response = await axiosInstance.post(`/extra-hours/`, payload);
      const data = response.data;
      if (response.status === 200 || response.status === 201) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return thunkAPI.rejectWithValue(err as ErrorResponse);
    }
  }
);

export const getExtraHoursOFMember = createAsyncThunk(
  'projects/getExtraHours',
  async (
    payload: {
      memberId: string;
      projectId: string;
    },
    thunkAPI
  ) => {
    try {
      const response = await axiosInstance.get(
        `/extra-hours/user/${payload.memberId}?project_id=${payload.projectId}`
      );
      const data = response.data;
      if (response.status === 200 || response.status === 201) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return thunkAPI.rejectWithValue(err as ErrorResponse);
    }
  }
);

export const deleteExtraHours = createAsyncThunk(
  'projects/getExtraHours',
  async (
    payload: {
      memberId: string;
      projectId: string;
    },
    thunkAPI
  ) => {
    try {
      const response = await axiosInstance.get(
        `/extra-hours/user/${payload.memberId}?project_id=${payload.projectId}`
      );
      const data = response.data;
      if (response.status === 200 || response.status === 201) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return thunkAPI.rejectWithValue(err as ErrorResponse);
    }
  }
);

export const bulkdeleteExtraHours = createAsyncThunk(
  'projects/deleteExtraHours',
  async (
    payload: {
      ids: string[];
    },
    thunkAPI
  ) => {
    try {
      const response = await axiosInstance.post(`/extra-hours/multiple-delete`, {
        extra_hours_ids: payload.ids,
      });
      const data = response.data;
      if (response.status === 200 || response.status === 201) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return thunkAPI.rejectWithValue(err as ErrorResponse);
    }
  }
);
