/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice, current, PayloadAction } from '@reduxjs/toolkit';
import {
  createUser,
  fetchOneUser,
  fetchUsers,
  filterUsers,
  getCurrentUser,
  updateUser,
} from './userThunk';

export interface UsersState {
  status: string;
  users: [] | any | null;
  nbUsers: number | null;
  filteredusers: [] | null;
  next: number | null;
  prev: number | null;
  error: string | null;
  user:
    | {
        id: string | null;
        fullName: string | null;
        department: { id: string; name: string };
        roles: any;
        avatarUrl: string | null;
      }
    | any
    | null;
}

const initialState: UsersState = {
  status: 'idle',
  filteredusers: [],
  users: [],
  nbUsers: 0,
  next: null,
  prev: null,
  user: null,
  error: null,
};

// state actions
const setPending = function (state: any) {
  state.error = null;
  state.status = 'loading';
};

export const setFulfilled = function (state: any, to: any, payload: any, push = false) {
  if (push) state.users.push(payload);
  else state[to] = payload;
  state.status = 'succeeded';
};

const setRejected = function (state: any, to: any, payload: any) {
  state.error = payload;
  state.status = 'failed';
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    initialise: (state, action) => {
      state.users = [];
    },

    setUser: (state, action) => {
      const user = {
        id: action.payload.id,
        fullName: action.payload.fullName,
        department: action.payload.departments[0],
        roles: action.payload.roles[0],
        avatarUrl: action.payload.avatarUrl,
      };
      state.user = user;
    },
    restoreUser: (state) => {
      state.user = initialState.user;
    },
  },
  extraReducers: (builder) => {
    // fetch users reducers
    builder.addCase(fetchUsers.pending, (state) => setPending(state));
    builder.addCase(fetchUsers.fulfilled, (state, action: PayloadAction<any>) => {
      setFulfilled(state, 'users', action.payload.users);
      setFulfilled(state, 'nbUsers', action.payload.nbUsers);
      setFulfilled(state, 'next', action.payload.next);
      setFulfilled(state, 'prev', action.payload.prev);
    });
    builder.addCase(fetchUsers.rejected, (state, action: PayloadAction<any>) =>
      setRejected(state, 'error', action?.payload)
    );

    // fetch a single user reducer
    builder.addCase(fetchOneUser.pending, (state) => setPending(state));
    builder.addCase(fetchOneUser.fulfilled, (state, action: PayloadAction<any>) => {
      const user = {
        fullName: action.payload.full_name,
        role: action.payload.roles[0].name,
        department: {
          id: action.payload.departments[0].id,
          name: action.payload.departments[0].name,
        },
        avatarUrl: action.payload.photo,
      };

      setFulfilled(state, 'user', user);
    });
    builder.addCase(fetchOneUser.rejected, (state, action: PayloadAction<any>) =>
      setRejected(state, 'error', action?.payload)
    );

    // create a user reducer
    builder.addCase(createUser.pending, (state) => setPending(state));
    builder.addCase(createUser.fulfilled, (state, action) => {
      const user = {
        id: action.payload.data.id,
        fullName: action.payload.data.full_name,
        roles: action.payload.data.roles,
        projects: action.payload.data.projects,
        activeProjects: [],
        departments: action.payload.data.departments,
        avatarUrl: action.payload.data.photo,
      };
      setFulfilled(state, 'users', user, true);
    });
    builder.addCase(createUser.rejected, (state, action) =>
      setFulfilled(state, 'error', action?.payload)
    );

    builder.addCase(getCurrentUser.pending, (state) => {
      state.error = null;
      state.status = 'loading';
    });
    builder.addCase(getCurrentUser.fulfilled, (state, action: PayloadAction<any>) => {
      state.user = action.payload;
      state.status = 'success';
    });
    builder.addCase(getCurrentUser.rejected, (state, action: PayloadAction<any>) => {
      state.error = action?.payload;
      state.status = 'failed';
    });
    builder.addCase(updateUser.pending, (state) => {
      state.error = null;
      state.status = 'loading';
    });
    builder.addCase(updateUser.fulfilled, (state, action: PayloadAction<any>) => {
      const newData = {
        first_name: action.payload.data.first_name,
        last_name: action.payload.data.last_name,
        avatarUrl: action.payload.data.photo,
        role: action.payload.data.roles && action.payload.data.roles[0].id,
        fullName: `${action.payload.data.first_name} ${action.payload.data.last_name}`,
        departments: [action.payload.data.department],
      };

      state.users = state.users?.map((user: any) => {
        if (current(user).id == action.payload.data.id) {
          return { ...user, ...newData };
        } else {
          return user;
        }
      });

      state.user = action.payload;
      state.status = 'success';
    });
    builder.addCase(updateUser.rejected, (state, action: PayloadAction<any>) => {
      state.error = action?.payload;
      state.status = 'failed';
    });

    builder.addCase(filterUsers.fulfilled, (state, action: PayloadAction<any>) => {
      state.filteredusers = action.payload;
      state.users = action.payload.results;
      state.status = 'success';
    });
  },
});

export const { initialise, restoreUser, setUser } = userSlice.actions;

export default userSlice.reducer;
