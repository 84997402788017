/* eslint-disable prefer-promise-reject-errors */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../../../utils/axios';
import { statusPayload } from './statusTypes';

export const fetchStatus = createAsyncThunk(
  'status/fetchStatus',
  async ({ page = 1, page_size = 100 }: { page?: number; page_size?: number }) => {
    const res = await axiosInstance.get(`/status/`, { params: { page, page_size } });

    const statuses = res.data.results;
    const nbStatuses = res.data.count;
    const next = res.data.next;
    const prev = res.data.prev;

    return { statuses, nbStatuses, next, prev };
  }
);

export const deleteStatus = createAsyncThunk('status/deleteStatus', async (ids, thunkAPI) => {
  let data;
  try {
    const response = await axiosInstance.delete(`/status/${ids}`);
    data = await response.data;
    if (response.status === 200) {
      return { id: ids, status: 'success' };
    }
    throw new Error(response.statusText);
  } catch (err) {
    return Promise.reject({
      message: err.message ? err.message : data?.message,
    });
  }
});
export const fetchOneStatus = createAsyncThunk(
  'status/fetchOneStatus',
  async (id: string, thunkAPI) => {
    let data;
    try {
      const response = await axiosInstance.get(`/status/${id}`);
      data = await response.data;
      if (response.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
export const createStatus = createAsyncThunk(
  'status/createStatus',
  async (payload: statusPayload, thunkAPI) => {
    let data;
    try {
      const response = await axiosInstance.post(`/status/`, payload);
      data = await response.data;
      if (response.status === 200 || response.status === 201) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

export const bulkUpdateStatus = createAsyncThunk(
  'status/bulk-update',
  async (data: { dragged_id: string; new_order: number }) => {
    try {
      const response = await axiosInstance.put(`/status/bulk-update`, data);
      if (response.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      const error = err.message ? err.message : "Can't update project";
      return Promise.reject(error);
    }
  }
);
